import React, { Fragment } from "react";
import { ReactComponent as AellaLogo } from "../../assets/svg/logo.svg";

interface IHeader {}

export const Header: React.FC<IHeader> = () => {
  return (
    <Fragment>
      <div className="bg-white py-[2vh] px-[8vw] justify-between">
        <AellaLogo />
        <div></div>
      </div>
    </Fragment>
  );
};
