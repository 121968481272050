import React from "react";
import { ReactComponent as SadFaceImage } from "./../../assets/svg/sad-face-icon.svg";
interface IErrorModal {
  titleText: string;
  text: string;
  buttonText: string;
  modalFunction: any;
}
const ErrorModal: React.FC<IErrorModal> = ({
  text,
  titleText,
  buttonText,
  modalFunction,
}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto my-6 mx-auto max-w-[400px] h-[365px]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10">
            {/*body*/}
            <div className="relative flex flex-col justify-center items-center">
              <SadFaceImage />

              <h1 className="text-2xl text-[#0B1E4B]  font-250 pt-10 leading-29 text-center">
                {titleText}
              </h1>
              <p className="text-[#5B5B5B] text-base font-50 pt-4 text-center">
                {text}
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center pt-10">
              <button
                className="bg-[#2054D2] text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => modalFunction(false)}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
export default ErrorModal;
