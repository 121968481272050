import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from './helpers/routes';
import HomePage from './pages/HomePage/HomePage';

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.SPECIFIED_LOAN} element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
