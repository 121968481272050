import React from "react";
interface ICards {
  cardImage: any;
  secondCardText: string;
  firstCardText: string;
}
const Cards: React.FC<ICards> = ({
  cardImage,
  firstCardText,
  secondCardText,
}) => {
  return (
    <>
      <div className="flex w-full md:w-[30vw] h-[50px] md:h-[70px] items-center mb-8 md:mb-0">
        <div className="h-[32px] w-[32px]">
          <img src={cardImage} alt="" />
        </div>
        <div className="flex flex-col ml-4">
          <div className="text-base font-250 leading-22">{firstCardText}</div>
          <div className="text-base font-250 leading-22">{secondCardText}</div>
        </div>
      </div>
    </>
  );
};
export default Cards;
