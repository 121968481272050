import axios from "axios";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://kabani7.aellapp.com"
    : process.env.REACT_APP_BASEURL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const getDataWithoutToken = async (url: string) => {
  try {
    const data = await axiosInstance({
      method: "GET",
      url,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const postDataWithoutToken = async (url: string, reqBody: any) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data.data;
};
