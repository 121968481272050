import React, { Fragment, useState, useEffect } from "react";
import { Header } from "../../components/Header/Header.component";
import { ReactComponent as LayoutImage } from "./../../assets/svg/layout-image.svg";
import { ReactComponent as FacebookIcon } from "./../../assets/svg/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "./../../assets/svg/twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "./../../assets/svg/instagram-icon.svg";
import walletImage from "./../../assets/svg/wallet-icon.svg";
import groupImage from "./../../assets/svg/group-icon.svg";
import insuranceImage from "./../../assets/svg/insurance-icon.svg";
import Cards from "../../components/Cards/Cards.component";
import { ReactComponent as CloseIcon } from "./../../assets/svg/close.svg";
import { ReactComponent as CopyIcon } from "./../../assets/svg/copy-icon.svg";
import { postDataWithoutToken } from "../../api/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import ErrorModal from "../../components/Modals/ErrorModal.component";
import formatMoney from "../../helpers/formatMoney";
import moment from "moment";
import { useParams } from "react-router-dom";
type UsersData = {
  loan: {
    first_name?: string;
    start_date?: string;
    end_date?: string;
    to_repay?: string;
  };
  accounts: Array<TAccount>;
};
interface TAccount {
  account_name: string;
  account_number: string;
  bank_name: string;
}
export default function HomePage() {
  const [showUserDetailsModal, setShowUserDetailsModal] = React.useState(false);
  const [showNoUserRunningLoanModal, setShowNoUserRunningLoanModal] =
    React.useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState<UsersData>();
  const [search, setSearch] = useState("");
  const [copy, setCopy] = useState("");
  const [invalidSearchMessage, setInvalidSearchMessage] = useState("");
  const params = useParams();
  const { id } = params as { id: string };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (search) {
      try {
        const data = await postDataWithoutToken(
          `/user/self-service/loan/${search}`,
          ""
        );
        setLoading(false);
        setUserData(data?.data);
        setShowUserDetailsModal(true);
        if (
          data?.data?.loan?.status === 1 ||
          data?.data?.loan?.status === 5 ||
          data?.data?.loan?.status === 6 ||
          data?.data?.loan?.status === 7 ||
          data?.data?.loan?.status === 9
        ) {
          setShowUserDetailsModal(true);
          setErrorMessage("");
          setShowNoUserRunningLoanModal(false);
        } else if (data?.data?.loan?.status === 3) {
          setShowUserDetailsModal(false);
          setErrorMessage("");
          setShowNoUserRunningLoanModal(true);
        } else {
          setErrorMessage("Loan ref not found");
          setShowNoUserRunningLoanModal(false);
          setShowUserDetailsModal(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(errorHandler(error));
        setShowUserDetailsModal(false);
        setShowNoUserRunningLoanModal(false);
      }
    } else {
      setLoading(false);
      setInvalidSearchMessage("Invalid Loan Ref");
      setErrorMessage("");
      setShowNoUserRunningLoanModal(false);
      setShowUserDetailsModal(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await postDataWithoutToken(
          `/user/self-service/loan/${id}`,
          ""
        );
        setLoading(false);
        setUserData(data?.data);
        setShowUserDetailsModal(true);
        if (
          data?.data?.loan?.status === 1 ||
          data?.data?.loan?.status === 5 ||
          data?.data?.loan?.status === 6 ||
          data?.data?.loan?.status === 7 ||
          data?.data?.loan?.status === 9
        ) {
          setShowUserDetailsModal(true);
          setErrorMessage("");
          setShowNoUserRunningLoanModal(false);
        } else if (data?.data?.loan?.status === 3) {
          setShowUserDetailsModal(false);
          setErrorMessage("");
          setShowNoUserRunningLoanModal(true);
        } else {
          setErrorMessage("Loan ref not found");
          setShowNoUserRunningLoanModal(false);
          setShowUserDetailsModal(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(errorHandler(error));
        setShowUserDetailsModal(false);
        setShowNoUserRunningLoanModal(false);
      }
    };
    if (id) {
      setSearch(id);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCopy = (text: string | undefined) => {
    setCopy("Copied.");
    if (text) {
      navigator.clipboard.writeText(text);
    }
    setTimeout(() => setCopy(""), 3000);
  };
  return (
    <Fragment>
      <div className='w-screen h-screen'>
        <Header />

        <div className='bg-white pt-[5vh] pb-[1vh] px-[8vw] flex justify-between items-center'>
          <div className='flex flex-col '>
            <div className='sm:text-56 text-4xl leading-50 sm:leading-67 font-300 text-[#232323] md:w-576 capitalize'>
              Welcome to the Aella Self Service Portal
            </div>
            <div className='text-lg leading-[27px] font-200 text-[#232323] mt-6'>
              Find yourself a quick solution to issues faced on the Aella app
              here.
            </div>

            <div className='flex flex-col mt-6'>
              <label
                className={`text-base tracking-0.03 text-[#0B1E4B]
              font-200 leading-4`}
              >
                Enter your Loan Reference
              </label>

              <form
                className='flex items-center mt-2 bg-grey-600'
                onSubmit={handleSubmit}
              >
                <input
                  type='text'
                  id='search'
                  name='search'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='0000000000'
                  className='py-2 pl-3 block w-36 sm:w-56 md:w-72 h-10 bg-white border rounded-l border-[#C5D4F6] placeholder:text-[#D9D9D9] font-300 text-base tracking-0.03 leading-6 focus:outline-none'
                />

                <button
                  className='flex p-0.5 xs:p-2 md:p-4 h-10 text-[#F3F5FC] rounded-r bg-[#2054D2] items-center transition ease-in-out duration-200 translate-10 font-200 md:font-250 text-base'
                  onClick={handleSubmit}
                  disabled={loading}
                  type='submit'
                >
                  Login to portal
                  {loading && (
                    <svg
                      className='animate-spin h-5 w-5 text-white ml-2'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        stroke-width='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className='hidden lg:block'>
            <LayoutImage />
          </div>
        </div>
        <div className='my-[5vh] px-[9vw]'>
          <div className='flex flex-col md:flex-row justify-between'>
            <Cards
              cardImage={walletImage}
              firstCardText='The easiest and most '
              secondCardText='effective way to grow wealth.'
            />
            <Cards
              cardImage={insuranceImage}
              firstCardText='A diversified health insurance '
              secondCardText='marketplace with the best options.'
            />
            <Cards
              cardImage={groupImage}
              firstCardText='No more panic. '
              secondCardText='You can pay the bills now and sort the rest late'
            />
          </div>
        </div>
        <div className='flex justify-between items-center py-[1vh] px-[8vw]'>
          <div className='flex'>
            <div className='mr-30.11'>
              <a
                href='https://www.facebook.com/aellaapp/'
                target='_blank'
                rel='noreferrer'
              >
                <FacebookIcon />
              </a>
            </div>
            <div className='mr-30.11'>
              <a
                href='https://twitter.com/aella'
                target='_blank'
                rel='noreferrer'
              >
                <TwitterIcon />
              </a>
            </div>
            <div className='mr-30.11'>
              <a
                href='https://www.instagram.com/aellaapp/'
                target='_blank'
                rel='noreferrer'
              >
                <InstagramIcon />
              </a>
            </div>
          </div>
          <div className='flex text-sm text-[#232323] leading-22 font-200'>
            <div className='mr-7 cursor-pointer'>
              {" "}
              <a
                href=' https://aellaapp.com/terms-and-conditions'
                target='_blank'
                rel='noreferrer'
              >
                Terms
              </a>
            </div>
            <div className='cursor-pointer'>
              <a
                href='https://aellaapp.com/privacy-policy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy
              </a>
            </div>
          </div>
        </div>
        <div className='px-[8vw] pt-2'>
          <div className='flex justify-between items-center pt-4 border-t border-[#CACACA]'>
            <div className='text-sm text-[#7B7B7B] md:leading-22 leading-3 font-200 pb-10'>
              © {new Date().getFullYear()} AellaCredit, All Rights Reserved
            </div>
            <div className='text-sm text-[#7B7B7B] md:leading-22 leadin-3 font-200 pb-10'></div>
          </div>
        </div>
      </div>
      {showUserDetailsModal ? (
        <>
          <div className='absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative  w-auto mb-6  mt-20 mx-auto max-w-2xl'>
              <div className='flex mb-2 justify-between'>
                <div></div>
                <div
                  onClick={() => setShowUserDetailsModal(false)}
                  className='cursor-pointer'
                >
                  <CloseIcon />
                </div>
              </div>
              {/*content*/}
              <div className='border-0 rounded-lg p-6 flex flex-col w-screen md:w-full bg-white outline-none focus:outline-none '>
                {/*header*/}
                <div className='leading-7 font-300 text-2xl text-[#232323]'>
                  Hi{"  "} {userData?.loan?.first_name},
                </div>
                <div className='text-base leading-22 font-50 text-[#7B7B7B] pt-[5px]'>
                  Here’s your loan details
                </div>
                {/* body */}

                <div className='border border-[#EDEFF5] rounded-lg bg-white md:w-[568px] w-screen h-[570px] p-6 mt-[23px]'>
                  <div className='flex flex-col '>
                    <div className='border border-[#EDEFF5] bg-white w-[520px] h-[247px] mb-6 shadow-3xl rounded-lg p-6'>
                      <div className='flex flex-col '>
                        <div
                          className={`flex py-1 px-2  rounded-2xl font-200 text-sm  bg-lightGreen w-36 items-center mb-4`}
                        >
                          <div
                            className={`rounded-full mr-2 w-2 h-2 bg-[#219653]`}
                          ></div>
                          <div className='text-[#219653]'>Outstanding loan</div>
                        </div>
                        <div className='border border-[#EDEFF5] bg-[#F3F5FC] w-[472px] max-h-[158px] shadow-3xl rounded-lg p-6'>
                          <div className='flex flex-col'>
                            <div className='pb-2 leading-17 text-sm text-gray font-200'>
                              Outstanding Amount
                            </div>
                            <div className='pb-2 leading-17 text-2xl text-[#0B1E4B] font-300'>
                              {userData?.loan?.to_repay &&
                                formatMoney(userData?.loan?.to_repay)}
                            </div>
                            <div className='flex justify-between mt-6'>
                              <div className='flex flex-col'>
                                <div className='pb-2 leading-17 text-sm text-gray font-200'>
                                  Loan Request Date:
                                </div>
                                <div className='leading-17 text-sm text-lightBlack font-300 text-[#232323]'>
                                  {moment(userData?.loan?.start_date).format(
                                    "Do of MMM, YYYY"
                                  )}
                                </div>
                              </div>
                              <div className='flex flex-col items-center'>
                                <div className='pb-2 leading-17 text-sm text-gray font-200'>
                                  Due Date:
                                </div>
                                <div className='leading-17 text-sm text-lightBlack font-300 text[#232323]'>
                                  {moment(userData?.loan?.end_date).format(
                                    "Do of MMM, YYYY"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='leading-7 font-300 text-2xl text-[#232323]'>
                      Repay Loan
                    </div>
                    <div className='text-base leading-6 font-50 text-[#7B7B7B] pt-2'>
                      Kindly use the account below to repay your loan.
                    </div>
                    <div className='border border-[#EDEFF5] bg-white w-[520px] h-[177px] mb-2.5 shadow-3xl rounded-lg p-6 mt-2.5'>
                      <div className='flex flex-col '>
                        <div className='text-base font-300'>
                          {" "}
                          Bank Account Number
                        </div>
                        <div className='border border-[#EDEFF5] bg-[#F3F5FC] w-[472px] max-h-[103px] shadow-3xl rounded-lg px-6 py-[5px] mt-3'>
                          <div className='flex flex-col'>
                            <div className='flex justify-between items-center'>
                              <div className='flex flex-col'>
                                <div className='pb-1.5 leading-17 text-sm text-gray font-200'>
                                  Account name
                                </div>
                                <div className='pb-2 leading-17 text-base text-lightBlack font-300 text-[#232323]'>
                                  {userData?.accounts[0]?.account_name}
                                </div>
                              </div>
                              <div className='border border-[#0B1E4B] rounded-[21px] px-2 py-1 '>
                                <div className='text-sm text-[#0B1E4B] leading-17 font-100'>
                                  {userData?.accounts[0]?.bank_name}
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div className='flex flex-col'>
                                <div className='pb-1.5 leading-17 text-sm text-gray font-200'>
                                  Account number
                                </div>
                                <div className='leading-17 text-base text-lightBlack font-300 text[#232323]'>
                                  {userData?.accounts[0]?.account_number}
                                </div>
                              </div>
                              <div className='flex items-center'>
                                <div
                                  className='inline-flex bg-[#D8A28F] w-[24px] h-[24px] rounded-[21px] items-center justify-center mr-2'
                                  onClick={() =>
                                    handleCopy(
                                      userData?.accounts[0]?.account_number
                                    )
                                  }
                                >
                                  <CopyIcon />
                                </div>
                                <div
                                  className={`${
                                    copy ? "font-200" : "font-300"
                                  } text-xs leading-[10px]`}
                                >
                                  {copy ? copy : "Copy"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]'></div>
        </>
      ) : showNoUserRunningLoanModal ? (
        <ErrorModal
          titleText='No outstanding loan'
          text='Looks like you don’t have an outstanding loan. To request a new loan, please download the Aella App.'
          buttonText='Got It!'
          modalFunction={setShowNoUserRunningLoanModal}
        />
      ) : errorMessage ? (
        <ErrorModal
          titleText={errorMessage}
          text='We cannot find any information linked to the reference provided. Kindly confirm the Loan reference and try again.'
          buttonText='Try Again'
          modalFunction={setErrorMessage}
        />
      ) : invalidSearchMessage ? (
        <ErrorModal
          titleText={invalidSearchMessage}
          text='Kindly Input a Valid Loan Reference and Try Again.'
          buttonText='Got It!'
          modalFunction={setInvalidSearchMessage}
        />
      ) : null}
    </Fragment>
  );
}
